<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="tedarikcidegerlendirme"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmamız Sözleşmeleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-dialog v-model="actionBarWait"
                          persistent>
                    <v-card color="transparent"
                            dark class="py-5">
                        <v-card-text class="text-center py-5">
                            <v-progress-circular indeterminate
                                                 :size="50"
                                                 color="white"></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var Tedarikcidegerlendirme_formInfo = {
        tabs: [
            { order: 1, header: 'FN_ITSupplierAssessment' },
        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_AssessmentInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'FL_Notes'
            }
        ],

        Columns: {
            id: {
                type: 'int',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },

            durum: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },

            degerlendirmekonusu_aciklama: {
                type: 'text',
                group: 2,
                sira: 10,
                name: 'degerlendirmekonusu_aciklama',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            degerlendirmedonemi:
            {
                type: 'text',
                group: 2,
                sira: 11,
                name: 'degerlendirmedonemi',
                label: 'FL_Period',
                edit: '',
                col: '6',
            },

            aksiyonack: {
                type: 'textarea',
                group: 2,
                sira: 20,
                col: '12',
                name: 'aksiyonack',
                label: 'FL_ExpectedActionToTaken',
                edit: '',
                validateMin: 1,
            },

            aksiyontarihi: {
                type: 'date',
                group: 2,
                sira: 24,
                name: 'aksiyontarihi',
                label: 'FL_ExpectedTimeToComplete',
                edit: '',
                col: '6',
            },

            aksiyonsonlanmatarihi: {
                type: 'date',
                group: 2,
                sira: 25,
                name: 'aksiyonsonlanmatarihi',
                label: 'FL_ActionDate',
                edit: '7',
                col: '6',
            },

            aksiyonsonuc: {
                type: 'textarea',
                group: 2,
                sira: 30,
                col: '12',
                name: 'aksiyonsonuc',
                label: 'FL_ActionTaken',
                edit: '7',
                validateMin: 1,
            },

            aksiyonfile: {
                type: 'file',
                group: 2,
                sira: 40,
                name: 'aksiyonfile',
                label: 'FL_AdditionalFiles',
                edit: '7',
                col: '12',
            },
            oonaynot: {
                type: 'history',
                group: 3,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: '7',
                label: ''
            },
        },
        form: 'tedarikcidegerlendirme',
        formid: 0,
        viewName: 'waitingSupplier',
        viewparameter: '',
        ignoreActions: true,
        files: ['aksiyonfile'],
        extdata: [],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { acosh } from 'core-js/core/number';

    export default {
        name: 'tedarikcidegerlendirme',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true },
            message: Object
        },
        data: function () {
            return {
                valid: false,
                showDamgaEkFile: '0',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: 'waitingSupplier',
                username: '',
                form: '',
                formname: 'tedarikcidegerlendirme',
                openallpanel: [0],
                currentform: 'tedarikcidegerlendirme',
                errors: []
            };
        },
        methods: {
            FormLoaded: function () {
                var currstate = this.GetState;
                if ("7".indexOf(currstate) != -1 && !this.actionBarWait) {
                    this.$store.commit('SetFormActions', [{ label: 'AL_RequestAddTime', action: 'EkSureIste', type: 'external' }, { label: 'AL_ComleteAction', action: 'Tamam', type: 'external' }]);
                } else {
                    this.$store.commit('SetFormActions', []);
                }
            },

            Selected: function ()
            {
                return;
            },

            NeedApprove: function (action) {
                var res;
                var formdata = this.GetFormData;
                var today = new Date();
                var aksiyonsonlanmatarihi = new Date(formdata.aksiyonsonlanmatarihi);
                switch (action)
                {
                    case 'Tamam':
                        if (aksiyonsonlanmatarihi < today)
                        {
                            res = this.$root.$i18n.t('DM_ActionResponsibility');
                        }
                        else
                        {
                            res = '';
                        }
                        break;
                    default:
                        res = '';
                        break;
                }
                return res;
            },
            FormButtonClicked(col) {
                var name = col.name;
                //if (name == 'ShowRequest') {
                //    //Request formunu göster
                //    var url = '/formart/saltalep/saltalep_frm.aspx?openformid=' + this.GetFormField('RequestId');
                //    window.open(url);
                //}
            },

            RowClicked: function (rowdata, table) {
                return;
            },

            RowButtonClicked: function (payload, tableName) {
                return;
            },

            ChangeAttribute: function (attribute, tableName) {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm: function (action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";
                var today = new Date();
                var formdata = this.GetFormData;

                if (action.action == "Tamam")
                {
                    if (formdata.aksiyonsonuc == '')
                    {
                        var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_ActionTaken') });
                        this.errors.push(err);
                        this.SetFieldError("aksiyonsonuc");
                    }
                    var aksiyonsonlanmatarihi = new Date(formdata.aksiyonsonlanmatarihi);
                    if (aksiyonsonlanmatarihi < today)
                    {
                        if (!formdata.oonaynot)
                        {
                            var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                            this.errors.push(err);
                            this.SetFieldError("oonaynot");
                        }
                    }
                }
                else if (action.action == "EkSureIste")
                {
                    if (!formdata.oonaynot)
                    {
                        var err = err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                        this.SetFieldError("oonaynot");
                    }
                }

                if (this.errors.length > 0) {
                    result = this.errors;
                }

                return result;
            },
        },

        mounted: function () {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', Tedarikcidegerlendirme_formInfo);
            var form = Tedarikcidegerlendirme_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form') {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0) {
                        Tedarikcidegerlendirme_formInfo.formid = formid;
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni') {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo => {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function () {
                return this.GetFormAttributes;
            },
            UserName() {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function () {
                    return this.GetFormLoadingStatus;
                }
            },
            GetState: function () {
                return this.$store.getters.GetFormState;
            },
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal) {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },
            FormAttributes: function (nval, oldval)
            {
                return;
            },

            $route: function (nval, oval) {
                console.log('WATCH Route :', nval, ' form:', this.formname);
                console.log('WATCH Route this:', this.$route);
                var form = Tedarikcidegerlendirme_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form') {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0) {
                            Tedarikcidegerlendirme_formInfo.formid = formid;
                            this.LoadFormData(formid);
                        }
                    }
                }
                else if (whatToShow == 'yeni') {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },
            drawer: function (n, o)
            {
                return;
            },
        }
    };
</script>
